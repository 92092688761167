<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Bimbingan PKM</b></div>
      <b-button
        squared
        variant="primary"
        @click="$router.push({path:'/creativity-program-guidance/add', query: {btnActive, filter, currentPage, perPage, totalRows}})"
        v-if="btnAccess"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div>
              <!-- Filter -->
              <div class="row justify-content-between mt-n3">
                <div class="col-md-9">
                  <button
                    class="btn mx-1 btn-timeseries"
                    @click="btnActiveOnClick('days')"
                    v-bind:class="{
                      'btn-info': btnActive.days,
                      'btn-outline-info': btnActive.days == false,
                    }"
                  >
                    Hari Ini
                  </button>
                  <button
                    class="btn mx-1 btn-timeseries"
                    @click="btnActiveOnClick('month')"
                    v-bind:class="{
                      'btn-info': btnActive.month,
                      'btn-outline-info': btnActive.month == false,
                    }"
                  >
                    Bulan Ini
                  </button>
                  <button
                    class="btn mx-1 btn-timeseries"
                    @click="btnActiveOnClick('lastMonth')"
                    v-bind:class="{
                      'btn-info': btnActive.lastMonth,
                      'btn-outline-info': btnActive.lastMonth == false,
                    }"
                  >
                    Bulan Kemarin
                  </button>
                  <button
                    class="btn mx-1 btn-timeseries"
                    @click="btnActiveOnClick('year')"
                    v-bind:class="{
                      'btn-info': btnActive.year,
                      'btn-outline-info': btnActive.year == false,
                    }"
                  >
                    Tahun Ini
                  </button>
                  <button
                    class="btn mx-1 btn-timeseries"
                    v-bind:class="{
                      'btn-info': btnActive.period,
                      'btn-outline-info': btnActive.period == false,
                    }"
                    @click="btnPeriodeOnClick"
                  >
                    Periode
                  </button>
                </div>
                <div
                  class="col-md-3 col-12"
                  v-if="currentUser.role_key_name != 'student'  || currentUser.role_key_name == 'admin_korprodi' || currentUser.role_key_name == 'admin_kepala_lab'"
                >
                  <b-input-group>
                    <b-form-input
                      type="text"
                      v-model="filter.student_name"
                      placeholder="Saring Nama Mahasiswa"
                      @keyup="filterByName"
                    ></b-form-input>
                  </b-input-group>
                </div>
              </div>

              <!-- timeseries filter -->
              <div class="row mt-3">
                <div class="col-md-9" v-if="onShowPeriod">
                  <span class="d-none d-sm-block">
                    <b-input-group>
                      <b-form-input
                        v-model="filter.start_date"
                        type="text"
                        placeholder="Awal"
                        autocomplete="off"
                        readonly
                      ></b-form-input>
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="filter.start_date"
                          button-only
                          right
                          reset-button
                          locale="id"
                          aria-controls="example-input"
                        ></b-form-datepicker>
                      </b-input-group-append>
                      <b-button squared variant="light">s/d</b-button>
                      <b-form-input
                        v-model="filter.end_date"
                        type="text"
                        placeholder="Akhir"
                        autocomplete="off"
                        readonly
                      ></b-form-input>
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="filter.end_date"
                          button-only
                          right
                          reset-button
                          locale="id"
                          aria-controls="example-input"
                        ></b-form-datepicker>
                      </b-input-group-append>
                      <template #append>
                        <b-button squared @click="pagination" variant="success"
                          >Cari</b-button
                        >
                        <b-button squared @click="resetFilter" variant="danger"
                          >Reset</b-button
                        >
                      </template>
                    </b-input-group>
                  </span>
                  <span class="d-block d-sm-none">
                    <div class="row">
                      <div class="col-12 pb-1">
                        <b-input-group>
                          <b-form-input
                            v-model="filter.start_date"
                            type="text"
                            placeholder="Awal"
                            autocomplete="off"
                            readonly
                          ></b-form-input>
                          <b-input-group-append>
                            <b-form-datepicker
                              v-model="filter.start_date"
                              button-only
                              right
                              reset-button
                              locale="id"
                              aria-controls="example-input"
                            ></b-form-datepicker>
                          </b-input-group-append>
                          <template #append>
                            <b-button
                              squared
                              @click="pagination"
                              variant="success"
                              >Cari</b-button
                            >
                            <b-button
                              squared
                              @click="resetFilter"
                              variant="danger"
                              >Reset</b-button
                            >
                          </template>
                        </b-input-group>
                      </div>
                      <div class="col-12 pt-0">
                        <b-input-group>
                          <b-button squared variant="light">s/d</b-button>
                          <b-form-input
                            v-model="filter.end_date"
                            type="text"
                            placeholder="Akhir"
                            autocomplete="off"
                            readonly
                          ></b-form-input>
                          <b-input-group-append>
                            <b-form-datepicker
                              v-model="filter.end_date"
                              button-only
                              right
                              reset-button
                              locale="id"
                              aria-controls="example-input"
                            ></b-form-datepicker>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </div>
                  </span>
                </div>
              </div>

              <div class="row">
                <div class="col ml-1" v-if="currentUser.role_id == 4 || currentUser.role_id == 5 || currentUser.role_id == 6">
                  <b-button
                    size="sm"
                    class="mr-1 btn-success"
                    v-b-tooltip.hover
                    title="Cetak"
                    @click="btnPrintOnClick()"
                    ><i class="fas fa-print px-0"></i
                  ></b-button>
                </div>
              </div>

              <b-table
                striped
                hover
                :items="items"
                :fields="fields"
                class="mt-3"
                responsive
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'action' ? '15%' : '' }"
                  />
                </template>

                <template #cell(status_id)="data">
                  <b-badge
                    class="btn-green text-white"
                    style="
                      background-color: #43a047;
                      border-color: #43a047;
                      cursor: pointer;
                    "
                    v-if="data.item.status_id"
                    @click="
                      showModalStatus(
                        data.item.id,
                        data.item.status_id,
                        data.item
                      )
                    "
                  >
                    Terkonfirmasi
                  </b-badge>
                  <b-badge
                    variant="danger"
                    v-if="!data.item.status_id"
                    style="cursor: pointer"
                    @click="
                      showModalStatus(
                        data.item.id,
                        data.item.status_id,
                        data.item
                      )
                    "
                  >
                    Belum terkonfirmasi
                  </b-badge>
                </template>

                <template #cell(action)="data">
                  <b-button
                    size="sm"
                    @click="data.toggleDetails"
                    class="mr-2 btn-circle"
                    variant="outline-info"
                  >
                    <i class="fas fa-eye px-0" v-if="!data.detailsShowing"></i>
                    <i
                      class="fas fa-eye-slash px-0"
                      v-if="data.detailsShowing"
                    ></i>
                  </b-button>
                  <template v-if="currentUser.role_id == 2">
                    <b-button
                      size="sm"
                      class="mr-1 btn-outline-success btn-circle"
                      v-b-tooltip.hover
                      title="Edit"
                      @click="
                        $router.push({
                          path:
                            '/creativity-program-guidance/edit/' + data.item.id,
                          query: {btnActive, filter, currentPage, perPage, totalRows}
                        })
                      "
                      v-if="btnAccess && data.item.status_id != 1"
                      ><i class="fas fa-edit px-0"></i
                    ></b-button>
                    <b-button
                      size="sm"
                      class="btn-outline-danger btn-circle"
                      v-b-tooltip.hover
                      title="Hapus"
                      @click="deleteData(data.item.id)"
                      v-if="btnAccess && data.item.status_id != 1"
                      ><i class="fas fa-trash px-0"></i
                    ></b-button>
                  </template>
                  <template v-if="currentUser.role_id != 2">
                      <b-button
                      size="sm"
                      class="mr-1 btn-outline-success btn-circle"
                      v-b-tooltip.hover
                      title="Edit"
                      @click="
                        $router.push({
                          path:
                            '/creativity-program-guidance/edit/' + data.item.id,
                            query: {btnActive, filter, currentPage, perPage, totalRows}
                        })
                      "
                      v-if="btnAccess"
                      ><i class="fas fa-edit px-0"></i
                    ></b-button>
                    <b-button
                      size="sm"
                      class="btn-outline-danger btn-circle"
                      v-b-tooltip.hover
                      title="Hapus"
                      @click="deleteData(data.item.id)"
                      v-if="btnAccess && data.item.status_id != 1"
                      ><i class="fas fa-trash px-0"></i
                    ></b-button>
                  </template>
                </template>

                <template #row-details="data">
                  <b-card no-body>
                    <b-card-body class="pb-3">
                      <h6>Isi Konsultasi</h6>
                      <b-card-text>{{
                        data.item.consultation_content
                      }}</b-card-text>

                      <h6>Catatan Dosen</h6>
                      <b-card-text class="mb-4">{{
                        data.item.teacher_notes ? data.item.teacher_notes : "-"
                      }}</b-card-text>

                      <b-button
                        class="card-link text-primary"
                        size="sm"
                        @click="data.toggleDetails"
                        >Tutup</b-button
                      >
                    </b-card-body>
                  </b-card>
                </template>
              </b-table>

              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Status -->
    <b-modal id="modal-status" @ok="verification">
      <template #modal-title>Konfirmasi Bimbingan PKM</template>
      <b-form-group id="input-group-consultation-content">
        <label for="input-consultation-content">Isi Konsultasi:</label>
        <b-form-textarea
          id="input-consultation-content"
          v-model="verificationRequest.consultation_content"
          placeholder="Isi konsultasi"
          rows="4"
          max-rows="8"
          readonly
        ></b-form-textarea>
        <small class="text-danger">{{ error.consultation_content }}</small>
      </b-form-group>

      <b-form-group id="input-group-teacher-notes">
        <label for="input-teacher-notes">Catatan Dosen:</label>
        <b-form-textarea
          id="input-teacher-notes"
          v-model="verificationRequest.teacher_notes"
          placeholder="Catatan Dosen"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.teacher_notes }}</small>
      </b-form-group>
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          id="radio-group-1"
          v-model="verificationRequest.status_id"
          :options="statusOptions"
          :aria-describedby="ariaDescribedby"
          name="radio-options"
        ></b-form-radio-group>
      </b-form-group>
    </b-modal>

    <Print :filterProps="filter" class="d-none" />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import { getUser } from "@/core/services/jwt.service.js";
import validation from "@/core/modules/ValidationModule.js";
import Print from "@/view/components/guidance-general/Print.vue"
import moment from "moment";

export default {
  components:{
    Print
  },
  data() {
    return {
      currentUser: getUser(),
      statusOptions: [
        { text: "Belum terkonfirmasi", value: 0 },
        { text: "Terkonfirmasi", value: 1 },
      ],
      //
      showDetail: `<i class="fas fa-eye-slash px-0"></i>`,
      hideDetail: `<i class="fas fa-eye px-0"></i>`,
      // filter
      filter: {
        student_name: "",
        teacher_id: getUser().role_id == 1 ? getUser().id : "",
        student_id: getUser().role_id == 2 ? getUser().id : "",
        guidance_type_id: 3,
        start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "student_name",
          label: "Mahasiswa",
          sortable: true,
        },
        {
          key: "teacher_1_name",
          label: "Pembimbing",
          sortable: true,
        },
        // {
        //   key: "teacher_2_name",
        //   label: "Dosen 2",
        //   sortable: true,
        // },
        {
          key: "consultation_date_display",
          label: "Tgl. Konsultasi",
          sortable: true,
        },
        {
          key: "registered_date_display",
          label: "Tgl. Didaftarkan",
          sortable: true,
        },
        {
          key: "status_id",
          label: "Status",
          sortable: true,
          tdClass: "nowrap",
        },
        {
          key: "verification_date_display",
          label: "Tgl. Konfirmasi",
          sortable: true,
          thClass: "nowrap",
        },
        { key: "action", label: "Aksi", tdClass: "nowrap" },
      ],
      items: [],
      // other
      verificationRequest: {
        id: "",
        status_id: "",
        teacher_notes: "",
        consultation_content: "",
      },
      error: {
        teacher_notes: "",
      },
      // access
      btnAccess: false,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      this.$root.$emit('pagination')
      let filterParams = `&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&guidance_type_id=${this.filter.guidance_type_id}&student_name=${this.filter.student_name}&teacher_id=${this.filter.teacher_id}&student_id=${this.filter.student_id}`;
      let response = await module.paginate(
        "api/student-consultations",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async btnPrintOnClick() {
      await this.$htmlToPaper("printGuidance");
    },

    showModalStatus(id, status_id, data) {
      if (
        getUser().role_key_name == "teacher" ||
        getUser().role_key_name == "admin_korprodi" ||
        getUser().role_key_name == "admin_kepala_lab"
      ) {
        this.verificationRequest.id = id;
        this.verificationRequest.status_id = status_id;
        this.verificationRequest.consultation_content =
          data.consultation_content;
        this.verificationRequest.teacher_notes = data.teacher_notes;
        this.$bvModal.show("modal-status");
      }
    },

    async verification() {
      this.verificationRequest["_method"] = "put";
      let response = await module.submit(
        this.verificationRequest,
        `api/student-consultations/${this.verificationRequest.id}/verification`
      );
      if (response.state != "error") {
        this.pagination();
        // Success
        Swal.fire({
          title: response.success.title,
          text: "Berhasil mengubah status bimbingan PKM",
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    filterByName() {
      // let timeout = null;

      // clearTimeout(timeout);

      // timeout = setTimeout(() => {
      //     console.log('jalankan pagination')
      //     this.pagination();
      //   }, 1000);
      this.currentPage = 1
      this.pagination();
    },

    // filter
    resetFilter() {
      // set btn Active
      this.btnActive.days = true;
      this.btnActive.month = false;
      this.btnActive.lastMonth = false;
      this.btnActive.year = false;
      this.btnActive.period = false;

      this.filter.student_name = "";
      this.filter.start_date = moment()
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      this.filter.end_date = moment()
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      this.pagination();
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnActiveOnClick(type) {
      console.log(type);
      if (type == "days") {
        // set time
        this.filter.start_date = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "months")
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .subtract(1, "months")
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.deleteGuidance(
        "api/student-consultations/" + id,
        "Berhasil menghapus bimbingan PKM"
      );
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1306") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Bimbingan PKM" }]);

    if(this.$route.query.hasOwnProperty("btnActive")){
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        for (const [key, value] of Object.entries(this.$route.query.btnActive)) {
          if(value){
            if(key == "days"){
              this.btnActive.days = true
            }else if(key == "month"){
              this.btnActive.month = true
            }else if(key == "lastMonth"){
              this.btnActive.lastMonth = true
            }else if(key == "period"){
              this.btnActive.period = true
              setTimeout(() => {
                this.filter.start_date = this.$route.query.filter.start_date
                this.filter.end_date = this.$route.query.filter.end_date
                this.pagination()
              }, 1000);
              this.onShowPeriod = true
            }else if(key == "year"){
              this.btnActive.year = true
            }
            this.btnActiveOnClick(key)
          }
        }
    }

    this.pagination();
    this.checkAccess();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}
</style>
